<template>
    <div class="ProblemFeedback">
    </div>
</template>

<script>
export default {

}
</script>

<style>
    .ProblemFeedback {
  margin: 0 auto;
  background-color: coral;
}
@media (min-width: 1400px) {
  .page-m,
  .comp-m,
  .title-m,
  .about-m,
  .bussiness-m,
  .footer-m,
  .ProblemFeedback,
  .box-m {
    width: 1700px;
  }
}
@media (min-width: 992px) {
  .page-m,
  .comp-m,
  .title-m,
  .about-m,
  .bussiness-m,
  .footer-m,
  .ProblemFeedback,
  .box-m {
    width: 90%;
  }
}
</style>